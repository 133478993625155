@import 'variables';
@import 'colors';

.container {
	padding: 0 $padding-side;
	max-width: $max-width;
	margin: auto;
}

.statbar {
	margin: 20px 0;
	display: flex;

	.header-name {
		margin-top: 0;
		margin-bottom: 12px;
	}

	.header-info {
		margin: 5px 15px 0 0;
		line-height: 1.3em;
	}

	.statbar-name {
		width: 50%;
	}

	.statbar-actions {
		width: 50%;
		text-align: right;
	}

	.action-item {
		margin: 5px 5px;
	}

	.interviewer-social-icon {
		width: 25px;
		height: 15px;
		padding: 0 5px;
	}
}

.btn {
  appearance: none;
  background-color: $btn;
  border-radius: 5px;
  border: 0;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 600;
  &:hover {
  	background-color: $btnHover
  }
  &:focus {
  	background-color: $btnFocus
  }
}

.btn-primary {
	background-color: $primaryBtn;
	&:hover {
		background-color: $primaryBtnHover;
	}
	&:focus {
		background-color: $primaryBtnFocus;
	}
}

.video-section {
	display: flex;
	height: 59vh;
}

.vjs-container {
  position: relative !important;
  width: 100% !important;
  height: auto !important;
}

.playlist-container {
	border: 1px solid $lightgrey;
	width: 50%;
	padding: 0 10px 10px;
	overflow-y: scroll;
}

.playlist-item {
	color: black;
	line-height: 1.5em;
	background-color: $lightgrey;
	padding: 10px;
	margin: 5px 0;
	border-radius: 5px;
	display: flex;
	cursor: pointer;
	&:hover {
		background-color: $darkGridline;
	}
}

.playlist-item-number {
	width: 6%;
	background-color: $primary;
	margin-right: 5px;
	color: white;
	text-align: center;
	padding: auto 0;
	font-weight: 600;
}

.playlist-item-question {
	width: 94%;
}

.playlist-item-time {
	color: $grey;
}

.portfolio-container {
	margin-top: 40px;
	height: 60vh;
}
