@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@font-face {
    font-family: "greycliff";

    src: url(../../static/media/greycliff-extrabold.677a4a65.eot);
    src: url(../../static/media/greycliff-extrabold.677a4a65.eot?#iefix) format("embedded-opentype"),
         url(../../static/media/greycliff-extrabold.b90149cc.woff) format("woff"),
         url(../../static/media/greycliff-extrabold.8ef0d13a.woff2) format("woff2");
    font-weight: 700;
    font-style: normal;
  }




/* CONTAINER */
.container-custom {
    padding: 0 20px;
    max-width: 1400px;
    margin: auto;
}

.statbar {
    margin: 20px 0;
    display: flex;
}

.statbar .header-name {
    margin-top: 0;
    margin-bottom: 12px;
    align-items: center;
    display: flex;
}

.myButton-custom {
    background-color: #ff2554;
    border-radius: 10px;
    border: 1px solid #ff2554;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-family: Arial;
    font-size: 17px;
    font-weight: 700;
    padding: 0 1rem;
    text-decoration: none;
    height: 48px;
    line-height: 48px;
}

/* BTN INTERVIEW */
.btn-interview-assessment-custom {
    background-color: #ff2554;
    border-radius: 10px;
    border: 1px solid #ff2554;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-family: Arial;
    font-size: 17px;
    font-weight: 700;
    padding: 0 1rem;
    text-decoration: none;
    height: 48px;
    line-height: 48px;
    letter-spacing: -.008em;
}


.btn-interview-assessment-custom-about{
    /* background-color: #ff2554; */
    background-color: white;
    border-radius: 10px;
    border: 1px solid #ff2554;
    display: inline-block;
    cursor: pointer;
    color: #ff2554;
    font-family: Arial;
    font-size: 17px;
    font-weight: 700;
    padding: 0 1rem;
    text-decoration: none;
    height: 48px;
    line-height: 48px;
    letter-spacing: -.008em;
}


.modal-custom {
    position: fixed;
    z-index: 55555;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;

    
    background-color: #000;
    background-color: rgba(0, 0, 0, .4);
}


.text-about-viva-custom{
    font-family: greycliff, sans-serif !important;
}
body {
    overflow-x: hidden;
    overflow-y:scroll !important;
}
body.modal-open {
    overflow: hidden;
    position:none;
}
body.viewport-lg {
    position: absolute; 
}
.modal-open .modal {
    overflow: auto !important;
    overflow-y: auto;
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0px !important;
}

.modal-custom .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 900px;
    margin: 1rem auto;
}

.modal .modal-content h1, .modal .modal-content h2, .modal .modal-content p {
    margin: 8px 0;
    text-align: left;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px !important;
    border-bottom: 1px solid transparent !important;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    font-size: 900px !important;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: 700;
}

.modal .modal-content td .questionText {
    width: 300px;
    height: 20px;
    padding: 1px 2px;
}

.modal .modal-content td {
    width: 120px;
}

.modal .modal-content .requiredField {
    color: #ff2554;
    font-weight: 700;
}

.modal .modal-content #institution-name, .modal .modal-content #teacher-name {
    width: 100% !important;
    margin-left: 1%;
}

.text-area-modal-style {
    width: 100% !important;
    margin-left: 1%;
    resize: none;
}


.modal .modal-content .text-score-style {
    color: grey;
    text-align: center;

}

.table-score {
    text-align: center;
}

.modal .modal-content .mobile-question-title {
    display: none;
}

.modal .modal-content .text-score-style {
    color: grey;
}

.modal .modal-content .mobile-question-title {
    display: none;
}

.modal .modal-content td {
    width: 120px;
    text-align: center;
}



.myQuestion {
    text-align: left !important;
}


.linkToOpenPortfolio {
    color: #ff2553 !important;
    text-decoration: none !important;
}

.linkToOpenPortfolio:hover {
    text-decoration: underline !important;
}

.video-js {
    display: block;
    vertical-align: top;
    box-sizing: border-box;
    color: #fff;
    background-color: #000;
    position: relative;
    padding: 0;
    font-size: 10px;
    line-height: 1;
    font-weight: 400;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    height: 100%;
}

.disclaimer {
    color: #ff2554;
    font-weight: 300;
    font-size: 14px;
}

.requiredFieldExplanation {
    font-style: italic;
    color: #b9b9b9;
    font-size: 14px;
} 
@font-face {
  font-family: "greycliff";
  src: url(../../static/media/greycliff-bold.8fa65770.eot);
  src: url(../../static/media/greycliff-bold.8fa65770.eot) format("embedded-opentype"), url(../../static/media/greycliff-bold.aa4235c4.woff) format("woff"), url(../../static/media/greycliff-bold.1c4bcc5b.woff2) format("woff2");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "greycliff";
  src: url(../../static/media/greycliff-extrabold.677a4a65.eot);
  src: url(../../static/media/greycliff-extrabold.677a4a65.eot) format("embedded-opentype"), url(../../static/media/greycliff-extrabold.b90149cc.woff) format("woff"), url(../../static/media/greycliff-extrabold.8ef0d13a.woff2) format("woff2");
  font-weight: 700;
  font-style: normal; }

body {
  color: #333;
  font-family: sans-serif !important;
  font-weight: 400;
  font-size: 1em; }

h1, h3 {
  color: #05214e;
  font-family: "greycliff", sans-serif !important; }

h1 {
  font-weight: 700;
  letter-spacing: -0.008em; }

h3 {
  font-weight: 600;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.3em; }

a {
  color: #FF2553;
  text-decoration: none; }
  a:hover {
    color: #E01F48;
    text-decoration: underline;
    cursor: pointer; }
  a:focus {
    color: #CC193F;
    text-decoration: underline; }



.container {
  padding: 0 20px;
  max-width: 1400px;
  margin: auto; }

.statbar {
  margin: 20px 0;
  display: flex; }
  .statbar .header-name {
    margin-top: 0;
    margin-bottom: 12px; }
  .statbar .header-info {
    margin: 5px 15px 0 0;
    line-height: 1.3em; }
  .statbar .statbar-name {
    width: 50%; }
  .statbar .statbar-actions {
    width: 50%;
    text-align: right; }
  .statbar .action-item {
    margin: 5px 5px; }
  .statbar .interviewer-social-icon {
    width: 25px;
    height: 15px;
    padding: 0 5px; }

.btn {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #ABABAB;
  border-radius: 5px;
  border: 0;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 600; }
  .btn:hover {
    background-color: #8D8D8D; }
  .btn:focus {
    background-color: #686767; }

.btn-primary {
  background-color: #FF2553; }
  .btn-primary:hover {
    background-color: #E01F48; }
  .btn-primary:focus {
    background-color: #CC193F; }

.video-section {
  display: flex;
  height: 59vh; }

.vjs-container {
  position: relative !important;
  width: 100% !important;
  height: auto !important; }

.playlist-container {
  border: 1px solid #D3D3D3;
  width: 50%;
  padding: 0 10px 10px;
  overflow-y: scroll; }

.playlist-item {
  color: black;
  line-height: 1.5em;
  background-color: #D3D3D3;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  display: flex;
  cursor: pointer; }
  .playlist-item:hover {
    background-color: #A9A9A9; }

.playlist-item-number {
  width: 6%;
  background-color: #FF2553;
  margin-right: 5px;
  color: white;
  text-align: center;
  padding: auto 0;
  font-weight: 600; }

.playlist-item-question {
  width: 94%; }

.playlist-item-time {
  color: #5F6368; }

.portfolio-container {
  margin-top: 40px;
  height: 60vh; }

.navBar {
  background-color: #05214e; }
  .navBar .container {
    display: flex;
    padding: 14px 20px;
    max-width: 1400px;
    margin: auto;
    color: white; }
  .navBar .companyName {
    text-align: left;
    width: 50%; }
  .navBar .companyTitle {
    font-size: 18px;
    font-weight: 600;
    margin: 5px 0; }
  .navBar .links {
    display: flex;
    width: 50%;
    justify-content: flex-end; }
  .navBar .links-item {
    margin: 5px 5px 0;
    color: white; }
  .navBar .logo {
    height: 30px; }

