@import 'colors';
@import 'variables';

.navBar {
	background-color: $navBarBg;

	.container {
		display: flex;
		padding: 14px $padding-side;
		max-width: $max-width;
		margin: auto;
		color: white;
	}

	.companyName {
		text-align: left;
		width: 50%;
	}

	.companyTitle {
		font-size: 18px;
		font-weight: 600;
		margin: 5px 0;
	}

	.links {
		display: flex;
		width: 50%;
		justify-content: flex-end;
	}

	.links-item {
		margin: 5px 5px 0;
		color: white;
	}

	.logo {
		height: 30px;
	}
}