@import 'colors';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

@font-face {
  font-family: "greycliff";
  src: url("../assets/fonts/greycliff-bold.eot");
  src: url("../assets/fonts/greycliff-bold.eot?#iefix") format("embedded-opentype"),
       url("../assets/fonts/greycliff-bold.woff") format("woff"),
       url("../assets/fonts/greycliff-bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "greycliff";
  src: url("../assets/fonts/greycliff-extrabold.eot");
  src: url("../assets/fonts/greycliff-extrabold.eot?#iefix") format("embedded-opentype"),
       url("../assets/fonts/greycliff-extrabold.woff") format("woff"),
       url("../assets/fonts/greycliff-extrabold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

body {
  color: #333;
  font-family: sans-serif !important;
  font-weight: 400;
  font-size: 1em;
}

h1, h3 {
  color: $primaryHeader;
  font-family: "greycliff", sans-serif !important;
}

h1 {
  font-weight: 700;
  letter-spacing: -0.008em;
}

h3 {
  font-weight: 600;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.3em;
}

a {
  color: $primaryBtn;
  text-decoration: none;
  &:hover {
    color: $primaryBtnHover;
    text-decoration: underline;
    cursor: pointer;
  }
  &:focus {
    color: $primaryBtnFocus;
    text-decoration: underline;
  }
}
