
@font-face {
    font-family: "greycliff";

    src: url("./assets/fonts/greycliff-extrabold.eot");
    src: url("./assets/fonts/greycliff-extrabold.eot?#iefix") format("embedded-opentype"),
         url("./assets/fonts/greycliff-extrabold.woff") format("woff"),
         url("./assets/fonts/greycliff-extrabold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
  }




/* CONTAINER */
.container-custom {
    padding: 0 20px;
    max-width: 1400px;
    margin: auto;
}

.statbar {
    margin: 20px 0;
    display: flex;
}

.statbar .header-name {
    margin-top: 0;
    margin-bottom: 12px;
    align-items: center;
    display: flex;
}

.myButton-custom {
    background-color: #ff2554;
    border-radius: 10px;
    border: 1px solid #ff2554;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-family: Arial;
    font-size: 17px;
    font-weight: 700;
    padding: 0 1rem;
    text-decoration: none;
    height: 48px;
    line-height: 48px;
}

/* BTN INTERVIEW */
.btn-interview-assessment-custom {
    background-color: #ff2554;
    border-radius: 10px;
    border: 1px solid #ff2554;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-family: Arial;
    font-size: 17px;
    font-weight: 700;
    padding: 0 1rem;
    text-decoration: none;
    height: 48px;
    line-height: 48px;
    letter-spacing: -.008em;
}


.btn-interview-assessment-custom-about{
    /* background-color: #ff2554; */
    background-color: white;
    border-radius: 10px;
    border: 1px solid #ff2554;
    display: inline-block;
    cursor: pointer;
    color: #ff2554;
    font-family: Arial;
    font-size: 17px;
    font-weight: 700;
    padding: 0 1rem;
    text-decoration: none;
    height: 48px;
    line-height: 48px;
    letter-spacing: -.008em;
}


.modal-custom {
    position: fixed;
    z-index: 55555;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;

    
    background-color: #000;
    background-color: rgba(0, 0, 0, .4);
}


.text-about-viva-custom{
    font-family: greycliff, sans-serif !important;
}
body {
    overflow-x: hidden;
    overflow-y:scroll !important;
}
body.modal-open {
    overflow: hidden;
    position:none;
}
body.viewport-lg {
    position: absolute; 
}
.modal-open .modal {
    overflow: auto !important;
    overflow-y: auto;
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0px !important;
}

.modal-custom .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 900px;
    margin: 1rem auto;
}

.modal .modal-content h1, .modal .modal-content h2, .modal .modal-content p {
    margin: 8px 0;
    text-align: left;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px !important;
    border-bottom: 1px solid transparent !important;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    font-size: 900px !important;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: 700;
}

.modal .modal-content td .questionText {
    width: 300px;
    height: 20px;
    padding: 1px 2px;
}

.modal .modal-content td {
    width: 120px;
}

.modal .modal-content .requiredField {
    color: #ff2554;
    font-weight: 700;
}

.modal .modal-content #institution-name, .modal .modal-content #teacher-name {
    width: 100% !important;
    margin-left: 1%;
}

.text-area-modal-style {
    width: 100% !important;
    margin-left: 1%;
    resize: none;
}


.modal .modal-content .text-score-style {
    color: grey;
    text-align: center;

}

.table-score {
    text-align: center;
}

.modal .modal-content .mobile-question-title {
    display: none;
}

.modal .modal-content .text-score-style {
    color: grey;
}

.modal .modal-content .mobile-question-title {
    display: none;
}

.modal .modal-content td {
    width: 120px;
    text-align: center;
}



.myQuestion {
    text-align: left !important;
}


.linkToOpenPortfolio {
    color: #ff2553 !important;
    text-decoration: none !important;
}

.linkToOpenPortfolio:hover {
    text-decoration: underline !important;
}

.video-js {
    display: block;
    vertical-align: top;
    box-sizing: border-box;
    color: #fff;
    background-color: #000;
    position: relative;
    padding: 0;
    font-size: 10px;
    line-height: 1;
    font-weight: 400;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    height: 100%;
}

.disclaimer {
    color: #ff2554;
    font-weight: 300;
    font-size: 14px;
}

.requiredFieldExplanation {
    font-style: italic;
    color: #b9b9b9;
    font-size: 14px;
} 