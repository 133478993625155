$success: #2cbe4e;
$warning: #F9A825;
$danger: #E53935;
$primary: #FF2553;
$primaryBtn: #FF2553;
$primaryBtnHover: #E01F48;
$primaryBtnFocus: #CC193F;
$primaryHeader: #05214e;
$btn: #ABABAB;
$btnHover: #8D8D8D;
$btnFocus: #686767;
$grey: #5F6368;
$lightgrey: #D3D3D3;
$defaultGridline: #CCC;
$darkGridline: #A9A9A9;
$graphLabel: #222;
$graphTick: #444;
$navBarBg: #05214e;

:export {
	success: $success;
	warning: $warning;
	danger: $danger;
	primary: $primary;
	primaryBtn: $primaryBtn;
	primaryBtnHover: $primaryBtnHover;
	primaryBtnFocus: $primaryBtnFocus;
	primaryHeader: $primaryHeader;
	btn: $btn;
	btnHover: $btnHover;
	btnFocus: $btnFocus;
	grey: $grey;
	lightgrey: $lightgrey;
	defaultGridline: $defaultGridline;
	darkGridline: $darkGridline;
	graphLabel: $graphLabel;
	graphTick: $graphTick;
	navBarBg: $navBarBg;
}